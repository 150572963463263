
let REDIRECT_URI = `https://${window.location.host}/`;

// const HOSTNAME = "localhost:8004/api";
const HOSTNAME = "forecastcollaborationapp.valuechainmetrics.com/api";
export const PRODUCT_NAME = "HR Management System";
export const VERSION = `3.0 beta`


export const MSALCONFIG = {
    auth: {
      clientId: "42032cd5-28d9-42a3-a8a2-ad88d260ea5f",
      authority: "https://login.microsoftonline.com/7f703489-df29-4251-9df2-369b7f22637f/",
      redirectUri: `${REDIRECT_URI}`,
    },
};

export const ApplyCellBg = (type) => (
  type=== 'Current Fcst'||type === "Total" || type === "Total Headcount"
  ? { backgroundColor: '#f5deb3', borderTop: '2px solid lightGray',fontWeight:"bold" } : {
    borderTop: '2px solid lightGray'
  });

// export let API_URL= `http://${HOSTNAME}`
export let API_URL= `https://${HOSTNAME}`
