import { UnauthenticatedTemplate, useAccount, useMsal } from '@azure/msal-react';
import {useEffect, React, useState} from 'react';
import { Box, Container, useColorModeValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CustomSpinner } from '../Spinner/Spinner';
import {VERSION,PRODUCT_NAME} from '../Constants'
import { Stack,Button,Text } from '@chakra-ui/react';
import { BsLock } from "react-icons/bs";



const Login = () => {
  const { instance,accounts } = useMsal();
  const account = useAccount(accounts[0])
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const Colour1 = useColorModeValue('bg-surface', 'gray.700')

  const versionTextStyle = {
    fontSize: "14px",
    fontWeight: "bold",
    color: "gray",
    padding: '10px',
  };
  


  const handleLoginRedirect = async () => {
    await instance.handleRedirectPromise().then((result) => {
      if (result && result.account ){
          navigate('/workforceManagement')
      }
      setIsLoading(false)
      
    });
  };

  useEffect(() => 
  {
    if(account) {
         navigate('/workforceManagement')
    }
    handleLoginRedirect();
  })

  const handleLogin = async () => {
    try {
      await instance.loginRedirect();
      handleLoginRedirect();
    } catch (error) {
      console.error('Login failed:', error);
    }
  };
  return (
    <Container 
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      >
      <UnauthenticatedTemplate>
      <Box
          className="login-form"
          maxW="md"
          mx="auto"
          p={6}
          borderRadius="xl"
          bg={Colour1}
          boxShadow="md"
        >
          <Text fontSize="3xl" color={"Black"} fontWeight="bold" textAlign="center" mb={6}>
            { PRODUCT_NAME }
          </Text>
          <Stack spacing={6}>
            <Button type="submit" onClick={handleLogin} colorScheme="teal" size="lg" leftIcon={<BsLock />}>
              Sign in With Microsoft
            </Button>
          </Stack>
          <Text style={versionTextStyle}>
            Version {VERSION}
          </Text>
        </Box>
        </UnauthenticatedTemplate>
        {isLoading &&
        <Box
        className="login-form"
        maxW="md"
        mx="auto"
        p={6}
        borderRadius="xl"
        bg={Colour1}
        boxShadow="md"
      >
        <CustomSpinner text="Login You In.. Please Wait" />
      </Box>
      }
      </Container>
      
      

  )
  
};

export default Login;
