import { useLocation } from 'react-router-dom';
import { Flex, Heading, Spacer, Link, Img } from '@chakra-ui/react';
import UserMenu from './UserMenu'
import { useNavigate } from 'react-router-dom';
import React from 'react';

export default function Header({dataSaved}) {

    const NavLink = ({ to, children,dataSave}) =>  {
        const { pathname } = useLocation();

        const isActive = pathname === to;
        const navigate = useNavigate();
        const handleClick = () => {
          if(!dataSave && !window.confirm("There are unsaved changes.All the changes would be lost if you navigate by clicking 'OK' . Click 'Cancel' to go back.")){
              return
        }else{
            navigate(to)
          }
        }

      
        return (
          <Link
            onClick={handleClick}
            px={4}
            fontSize='md'
            fontWeight={isActive ? 'bold' : 'normal'}
            textDecorationLine={isActive?'underline':''}
            _hover={{ textDecoration: 'none',textDecorationLine:"underline" }}
          >
            {children}
          </Link>
        );
    }
    return(
        <Flex
        as="nav"
        bg="blackAlpha.900"
        color="white"
        py={2}
        px={4}
        alignItems="center"
        > 
            <Heading size="md">HR AOP Consolidation System</Heading>
            <Spacer />
            {/* <NavLink to="/regionalDashboard" dataSave={dataSaved} >Forecast Editor</NavLink>
            <NavLink to="/regionalDashboardReport" dataSave={dataSaved} >Forecast Report</NavLink>
            <NavLink to="/regionalView" dataSave={dataSaved} >Regional View</NavLink> */}
            {/* User profile */}
            <UserMenu dataSave={dataSaved}/>
        </Flex>
    );
}