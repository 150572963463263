import React, { useState, useEffect, useRef } from 'react';
import { Td } from '@chakra-ui/react';
import { ApplyCellBg } from '../Constants';

function EditableDistributorCell({index,value, rowId, columnID, onUpdate,tableData,setTableData}) {
  const [cellValue, setCellValue] = useState(!value&&value!==0?'':parseFloat(value).toFixed(0));
  const cellRef = useRef(null);
  const [width, setWidth] = useState(value?value.toString().length+2:0);
  const[valueEdited,setValueEdited]=useState(false)

  useEffect(() => {
    setCellValue(!value&&value!==0?'':parseFloat(value).toFixed(0))
    setWidth(value?value.toString().length+2:0);
    setValueEdited(false)
  }, [value]);

  useEffect(()=>{
    const timeOutId = setTimeout(() =>{
      if(valueEdited){
      onUpdate(cellValue, columnID, rowId)
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  },[cellValue,tableData,columnID,onUpdate,setTableData,rowId,valueEdited])

  const handleInput = (e) => {
    const newValue = e.target.value;
    setValueEdited(true)
    setWidth(newValue.length+2);
    setCellValue(newValue);
    // onUpdate(cellValue, columnID, rowId,forecast_version,dealer_no);
  }

  return (
      <Td textAlign style={{...ApplyCellBg('After Today'),overflow:"auto",whiteSpace:"nowrap"}}>
            <input
            type="number"
            ref={cellRef}
            onInput={handleInput}
            value={cellValue}
            suppressContentEditableWarning={true}
            style={{
              // backgroundColor: '#f5deb3', // Add a very light background color
              padding: '7px', // Add some padding for a better look
              border: '1px solid gray',
              textAlign: 'center',
              borderRadius: '5px',
              width:width +'ch',
              minWidth:'40px'
            }}
          />
    </Td>
  );
}


export default EditableDistributorCell;