import { Box, Table, Thead, Tr, Th, Flex, Container, Stack, Tabs, TabList, TabPanels, TabPanel, Tab, Tbody, HStack, Button, Spacer, Td, } from '@chakra-ui/react';
import { useState, useEffect,useCallback,useRef } from 'react';
import { AuthenticatedTemplate } from '@azure/msal-react';
import Header from '../Header/Header'
import { useMsal, useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../Constants';
import { pivotParseData } from './DashboardTableHelper';
import { IoMdPrint } from 'react-icons/io';
import SaveButton from '../Save';
import { FormatDateToMonthYear } from './DashboardTableHelper';
import { ApplyCellBg } from '../Constants';
import EditableDistributorCell from './EditableDistributorCell';
import { updateTotals } from './DashboardTableHelper';
import { useToast } from '@chakra-ui/react';
import { CustomSpinner } from '../Spinner/Spinner';
import { useColorModeValue } from '@chakra-ui/react';


export default function WorkforceManagement() {
  const [loading,setLoading] = useState(true)
  const [isDataSaved, setIsDataSaved] = useState(true)
  const { accounts } = useMsal();
  const navigate = useNavigate()
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const account = useAccount(accounts[0] || {});
  const [tableData, setTableData] = useState({});
  const [totals,setTotals]=useState({})
  const [changesTracker,setChangesTracker]=useState({})
  const date_today=new Date()
  const date_today_start=new Date("2023-03-31")
  const [cPEmployeeTable,setCPEmployeeTable] = useState({})
  const [pricingTable,setPricingTable] = useState({})
  const [costTotals,setCostTotals]= useState({})
  const [annualTotals,setAnnualTotals]=useState({})
  const [avgHeadcount,setAvgHeadcount]=useState({})
  const toast = useToast()
  const toastIdRef = useRef()
  const Colour1 = useColorModeValue('bg-surface', 'gray.700')
  // const [filter, setFilter] = useState('');



  useEffect(() => {
    if (!account) {
      navigate("/")
    } else {
      console.log(account?.idTokenClaims?.EmployeeID)
      fetch(`${API_URL}/Transaction_Table`,
        {

          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ "departmentName": account?.idTokenClaims?.EmployeeID })
        })
        .then(response => response.json())
        .then((data) => {
          pivotParseData(data, setTableData,setTotals,setCPEmployeeTable,setPricingTable,setCostTotals,setAnnualTotals,setAvgHeadcount)
          setLoading(false)
        })
        .catch((error) => {
          const toastSettings = {
          description: error,
          status: 'error',
          isClosable: true,
          onClose: () => {
          window.location.reload()
          toastIdRef.current = null
          }
        }
        if (toastIdRef.current) {
            toast.update(toastIdRef.current, toastSettings)
          } else {
            toast(toastSettings)
          }
          
          console.log(error)
          });
      setIsDataSaved(true)
    }
  }, [account, navigate, setIsDataSaved,toast])

  const uploadData = () => {
    console.log(changesTracker)
    toastIdRef.current = toast({
      description: "loading",
      status: "loading",
      duration:null
    })
    fetch(`${API_URL}/update_Transaction_Table`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body:JSON.stringify({"departmentName":account?.idTokenClaims?.EmployeeID, "changesTracker":changesTracker})
    })
      .then((response) => response.json())
      .then((data) => {
      pivotParseData(data, setTableData,setTotals,setCPEmployeeTable,setPricingTable,setCostTotals,setAnnualTotals,setAvgHeadcount)
      setChangesTracker({})
      setIsButtonDisabled(true)
      toast.update(toastIdRef.current, {description: "Data uploaded succesfully", status: "success", duration: 3000})
      console.log("Data:", data)
    })
      .catch((error) => {
      const toastSettings = {
      description: error,
      status: 'error',
      isClosable: true,
      onClose: () => {
      window.location.reload()
      toastIdRef.current = null
      }
    }
    if (toastIdRef.current) {
        toast.update(toastIdRef.current, toastSettings)
      } else {
        toast(toastSettings)
      }
      
      console.log(error)
      });
  }

  console.log(tableData)
  const handleChangesTracker = useCallback((newValue, columnID, rowId) => {
    setIsDataSaved(false)
    setChangesTracker((prevChanges) => {
        const updatedChanges = { ...prevChanges };
        const key = `${rowId},${columnID}`
        updatedChanges[key] = newValue?newValue:0;
        return updatedChanges;
    });
    setIsButtonDisabled(false)
},[setIsButtonDisabled,setChangesTracker,setIsDataSaved])


  return (
    <AuthenticatedTemplate>
      <Box minHeight="100vh">
        <Header dataSaved={isDataSaved} />
        <Flex flex="1" flexDirection="column">
          <Container maxW="container.xxl" py={8}>
            <Stack spacing={8}>
              <Table variant="striped" size="md" width={"550px"}>
                <Thead bg={"blackAlpha.900"}>
                  {account?.idTokenClaims?.EmployeeID && <Tr>
                    <Th color="white">Department Name :</Th>
                    <Th color="white">{account?.idTokenClaims?.EmployeeID}</Th>
                  </Tr>
                  }
                </Thead>
              </Table>
              {loading && <Box
        className="login-form"
        maxW="md"
        mx="auto"
        p={6}
        borderRadius="xl"
        bg={Colour1}
        boxShadow="md"
      >
        <CustomSpinner text="Loading Editor..." />
      </Box>}
              {!loading && <>
              <Tabs variant="enclosed" bg="white" boxShadow="md">
                <TabList>
                  <Tab>HRM Planning</Tab>
                  <Tab>HRM Cost Budget</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {(Object.keys(tableData).length > 0) && (Object.keys(tableData.data).length > 0) &&
                      <Box minHeight="60vh">
                        <HStack spacing={4}>
                          <SaveButton isButtonDisabled={isButtonDisabled} uploadData={uploadData} />
                          <Button leftIcon={<IoMdPrint />} style={{ marginTop: "16px" }} px={6} py={2} fontSize="md" fontWeight="bold" onClick={() => window.print()} colorScheme='blue'>
                            Print
                          </Button>
                          <Spacer />

                        </HStack>
                        <Box height="50vh" overflow="auto" textAlign="center"  >
                          <Box id="printableArea">
                          <Table textAlign="center" >
                            <Thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }} textAlign="center">
                              <Tr key="Table_Headers">
                                {/* <Th textAlign>Warehouse</Th> */}
                                <Th key="Role" textAlign style={{ minWidth: "200px" }}>Role</Th>
                                {/* {tableHeaders} */}
                                {tableData.uniqueDates.map((date) => {
                                  return <Th key={`${date}-Header`} textAlign>{FormatDateToMonthYear(date)}</Th>
                                })}
                              </Tr>
                            </Thead>
                            <Tbody>
                              {tableData?.data && tableData?.uniqueRoles?.map((key, index) => (
                                <Tr key={index}>
                                  <Td textAlign style={ApplyCellBg(key)}>{key}</Td>
                                  {
                                    tableData.uniqueDates && tableData.uniqueDates
                                      .map((date, index) => {
                                      const date_month= new Date(date)
                                      return date_month>date_today_start?
                                      <EditableDistributorCell 
                                      index={key+date}
                                      value={tableData.data[key.concat("-", date)]["Total Count"]}
                                      rowId={date} 
                                      columnID={key}
                                      onUpdate={handleChangesTracker}
                                      tableData={tableData}
                                      setTableData={setTableData}
                                      />
                                      
                                      :
                                         <Td key={index} style={ApplyCellBg(key)} textAlign >{tableData.data[key.concat("-", date)]["Total Count"]}</Td>;
                                      })}
                                </Tr>
                              ))}
                              <Tr key="Totals">
                              <Td textAlign style={ApplyCellBg("Total Headcount")}>Total Headcount</Td>
                              {Object.keys(tableData).length>0 && tableData.uniqueDates.length>0 ?
                                  tableData.uniqueDates.map((date)=>{
                                     return (<Td key={`totals-${date}`} textAlign style={ApplyCellBg("Total Headcount")}>
                                      {totals[date]}
                                     </Td> )
                                  }):<Td></Td>
                                }
                              </Tr> 
                              <Tr key="Cost Per Employee">
                              <Td textAlign style={ApplyCellBg("Total Headcount")}>Cost Per Employee</Td>
                              {Object.keys(tableData).length>0 && tableData.uniqueDates.length>0 ?
                                  tableData.uniqueDates.map((date)=>{
                                     return (<Td key={`totals-${date}`} textAlign style={ApplyCellBg("Total Headcount")}>
                                      {cPEmployeeTable[date] && Number(cPEmployeeTable[date]).toLocaleString('en-US', {
                                        style: 'currency',
                                        currency: 'INR',
                                        maximumFractionDigits: 0,
                                    })}
                                     </Td> )
                                  }):<Td></Td>
                                }
                              </Tr> 
                            </Tbody>
                          </Table>
                          </Box>
                        </Box>
                      </Box>
                    }
                  </TabPanel>
                  <TabPanel>
                    {(Object.keys(tableData).length > 0) && (Object.keys(tableData.data).length > 0) &&
                      <Box minHeight="60vh">
                        <HStack spacing={4}>
                          <Button leftIcon={<IoMdPrint />} style={{ marginTop: "16px" }} px={6} py={2} fontSize="md" fontWeight="bold" onClick={() => window.print()} colorScheme='blue'>
                            Print
                          </Button>
                          <Spacer />

                        </HStack>
                        <Box height="50vh" overflow="auto" textAlign="center" >
                          <Box id="printableArea">
                          <Table textAlign="center">
                            <Thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 2 }} textAlign="center">
                              <Tr key="Table_Headers">
                                {/* <Th textAlign>Warehouse</Th> */}
                                <Th key="Role" textAlign style={{ minWidth: "200px" }}>Cost To Company</Th>
                                {/* {tableHeaders} */}
                                {tableData.uniqueDates.map((date) => {
                                  return <Th key={`${date}-Header`} textAlign>{FormatDateToMonthYear(date)}</Th>
                                })}
                                <Th key="Annual Total" textAlign style={{ minWidth: "200px" ,fontWeight:"bold"}}>Annual Total</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {tableData?.data && tableData?.uniqueRoles?.map((key, index) => (
                                <Tr key={index}>
                                  <Td textAlign style={ApplyCellBg(key)}>{key}</Td>
                                  {
                                    tableData.uniqueDates &&Object.keys(pricingTable).length>0
                                    &&tableData.uniqueDates
                                      .map((date, index) => {
                                         return <Td key={index} style={ApplyCellBg(key)} textAlign >{(parseInt(tableData.data[key.concat("-", date)]["Total Count"])*parseFloat(pricingTable[key])).toLocaleString('en-IN', {
                                          style: 'currency',
                                          currency: 'INR',
                                          maximumFractionDigits: 0,
                                      })
                                         }</Td>;
                                         
                                      })
                                      }
                                      <Td textAlign style={ApplyCellBg(key)}>{parseFloat(annualTotals[key]).toLocaleString('en-IN', {
                                        style: 'currency',
                                        currency: 'INR',
                                        maximumFractionDigits: 0,
                                    })}</Td> 
                                </Tr>
                              ))}
                              <Tr key="Totals">
                              <Td textAlign style={ApplyCellBg("Total Headcount")}>Total Cost To Company</Td>
                              {Object.keys(tableData).length>0 && tableData.uniqueDates.length>0 ?
                                  tableData.uniqueDates.map((date)=>{
                                     return (<Td key={`totals-${date}`} textAlign style={ApplyCellBg("Total Headcount")}>
                                      {parseFloat(costTotals[date]).toLocaleString('en-IN', {
                                        style: 'currency',
                                        currency: 'INR',
                                        maximumFractionDigits: 0,
                                    })}
                                     </Td> )
                                  }):<Td></Td>
                                }
                              <Td textAlign style={ApplyCellBg("Total Headcount")}>{parseFloat(costTotals["Total"]).toLocaleString('en-IN', {
                                        style: 'currency',
                                        currency: 'INR',
                                        maximumFractionDigits: 0,
                                    })}</Td>
                              </Tr> 
                              <Tr key="Cost Per Employee">
                              <Td textAlign style={ApplyCellBg("Total Headcount")}>Cost Per Employee</Td>
                              {Object.keys(tableData).length>0 && tableData.uniqueDates.length>0 ?
                                  tableData.uniqueDates.map((date)=>{
                                     return (<Td key={`totals-${date}`} textAlign style={ApplyCellBg("Total Headcount")}>
                                      {cPEmployeeTable[date] && Number(cPEmployeeTable[date]).toLocaleString('en-IN', {
                                        style: 'currency',
                                        currency: 'INR',
                                        maximumFractionDigits: 0,
                                    })}
                                     </Td> )
                                  }):<Td></Td>
                                }
                                <Td textAlign style={ApplyCellBg("Total Headcount")}>{parseFloat(cPEmployeeTable["Total"]).toLocaleString('en-IN', {
                                        style: 'currency',
                                        currency: 'INR',
                                        maximumFractionDigits: 0,
                                    })}</Td>
                              </Tr> 
                            </Tbody>
                          </Table>
                          </Box>
                        </Box>
                      </Box>
                    }
                  </TabPanel>
                </TabPanels>
              </Tabs>
              </>}
            </Stack>
          </Container>



        </Flex>
      </Box>
    </AuthenticatedTemplate>
  );

}