
export function  pivotParseData (data, setPivotedData,setTotals,setCPEmployeeTable,setPricingTable,setCostTotals,setAnnualTotals,setAvgHeadcount) {
    const uniqueDates = GetUniqueValues(Object.values(data.data),"Month-Year")
    const uniqueRoles = GetUniqueValues(Object.values(data.data),"Role")
    const DataTemp={}
    const date_today = new Date()
    const date_today_start=new Date(date_today.getFullYear(),date_today.getMonth(),1)
    Object.entries(data.data).forEach((key)=>{
      const date_temp=new Date(key[1]["Month-Year"])
      const month_start=new Date(date_temp.getFullYear(),date_temp.getMonth(),1)
      if(date_today_start>month_start){
        DataTemp[key[0]]={...key[1],"Total Count":(key[1]["Existing_Count"]+key[1]["New Onboard"]-key[1]["Outgoing Count"])}
      }
    else {
      DataTemp[key[0]]={...key[1],"Total Count":(key[1]["Existing_Count"]+key[1]["Planned Recruitment"]-key[1]["Outgoing Count"])}
    }
    })
    const totals = {}
    var avgHeadcount = 0
    const costPerEmployee = {}
    const costTotals= {"Total":0,}
    const annualTotals = {}
     uniqueDates.forEach((date)=>{
        totals[date]=0
        costPerEmployee[date]=0
        uniqueRoles.forEach(role=>{
          totals[date]=DataTemp[role+'-'+date]["Total Count"]?totals[date]+parseInt(DataTemp[role+'-'+date]["Total Count"]):totals[date]
          costPerEmployee[date]=DataTemp[role+'-'+date]["Total Count"]&&data["final_file"][role]?costPerEmployee[date]+parseInt(DataTemp[role+'-'+date]["Total Count"])*parseFloat(data["final_file"][role]):costPerEmployee[date]
        })
        costTotals[date]=costPerEmployee[date]
        costTotals["Total"]=costTotals["Total"]+costTotals[date]
        costPerEmployee[date]=costPerEmployee[date]/totals[date]
        avgHeadcount=avgHeadcount+totals[date]
      })
      costPerEmployee["Total"]=costTotals["Total"]
      uniqueRoles.forEach(role=>{
        annualTotals[role]=0
        uniqueDates.forEach((date)=>{
          annualTotals[role]=DataTemp[role+'-'+date]["Total Count"]&&data["final_file"][role]?annualTotals[role]+parseInt(DataTemp[role+'-'+date]["Total Count"])*parseFloat(data["final_file"][role]):annualTotals[role]
        })

      })
      avgHeadcount=avgHeadcount/uniqueDates.length
    costPerEmployee["Total"]=costTotals["Total"]/avgHeadcount
    setAvgHeadcount(avgHeadcount)
    setAnnualTotals(annualTotals)    
    setTotals(totals)
    setPivotedData({data:DataTemp,uniqueDates,uniqueRoles})
    setCostTotals(costTotals)
    setCPEmployeeTable(costPerEmployee)
    setPricingTable(data["final_file"])
}


export const GetUniqueValues = (array, key) => {
    return [...new Set(array.map((item) => item[key]))]
  };

  export const FormatDateToMonthYear = (dateStr) => {
    const date = new Date(dateStr.replace(/-/g, '\/'));
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return `${month}-${year}`;
  };

//   export const updateTotals=(changesTracker,tableData,totals,setTotals,setTableData,cPEmployeeTable,setCPEmployeeTable,pricingTable,setCostTotals,setAnnualTotals,setAvgHeadcountcostTotals,costTotals,annualTotals,avgHeadcount)=>{
//       var totals_temp=totals
//       var tableDataTemp=tableData
//       var cPEmployeeTableTemp=  cPEmployeeTable
//       var annualTotalsTemp=annualTotals
//       var costTotalsTemp=costTotals
//       var TotalHeadcount=avgHeadcount*tableData.uniqueDates.length
//       console.log(annualTotals,costTotals)
//       Object.entries(changesTracker).forEach(([key, value3])=>{
//         const [rowId,columnID]=key.split(",")
//         console.log(rowId,columnID)
//         var value=value3?value3:0
//           var costTotal = cPEmployeeTable[rowId]*totals_temp[rowId] - tableDataTemp["data"][columnID+"-"+rowId]["Total Count"]*parseFloat(pricingTable[columnID])+parseFloat(pricingTable[columnID])*parseInt(value)
//           costTotalsTemp["Total"]=costTotalsTemp["Total"]-costTotalsTemp[rowId]+costTotal
//           costTotalsTemp[rowId]=costTotal
//           annualTotalsTemp[columnID]=annualTotalsTemp[columnID] - tableDataTemp["data"][columnID+"-"+rowId]["Total Count"]*parseFloat(pricingTable[columnID])+parseFloat(pricingTable[columnID])*parseInt(value)
//           totals_temp[rowId]=totals_temp[rowId]-tableDataTemp["data"][columnID+"-"+rowId]["Total Count"]+parseInt(value)
//           cPEmployeeTableTemp[rowId]=costTotal/totals_temp[rowId]
//           var value2=parseInt(value)-parseInt(tableDataTemp.data[columnID+"-"+rowId]["Existing_Count"])
//           TotalHeadcount=TotalHeadcount+parseInt(value)-parseInt(tableDataTemp.data[columnID+"-"+rowId]["Total Count"])
//           tableDataTemp.data[columnID+"-"+rowId]["Total Count"]=value
//           if(value2>=0){
//             tableDataTemp.data[columnID+"-"+rowId]["Planned Recruitment"]=value2
//             tableDataTemp.data[columnID+"-"+rowId]["Outgoing Count"]=0
//           }
//           else{
//             tableDataTemp.data[columnID+"-"+rowId]["Outgoing Count"]=-value2
//             tableDataTemp.data[columnID+"-"+rowId]["Planned Recruitment"]=0
//           }
//           var avgHeadcountTemp=TotalHeadcount/tableData.uniqueDates.length
//           cPEmployeeTableTemp["Total"]=costTotalsTemp["Total"]/avgHeadcountTemp
//           }
//       )
//       console.log(annualTotalsTemp)
//       setCostTotals(costTotalsTemp)
//       setTotals(totals_temp)
//       setTableData(tableDataTemp)
//       setCPEmployeeTable(cPEmployeeTableTemp)
//       setAnnualTotals(annualTotalsTemp)
// } Not using this function for now as we are sending the updated from backend to front end