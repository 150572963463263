import React from 'react';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from './Login/Login';
import WorkforceManagement from './workforceManagement/WorkforceManagement';
import theme from './theme';


function App() {
  return (
    <ChakraProvider >
        <Router>
				<div className="App">
					<Routes>
						<Route index path="/" element={<Login />} />
            <Route index path="/workforceManagement" element={<WorkforceManagement/>} />
					</Routes>
				</div>
			</Router>
    </ChakraProvider>
    // <p>hi</p>
  );
}

export default App;
